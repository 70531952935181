$(document).ready(function () {
    const queryParamEvent = new Event('FFQueryParamInit');

    let businessMode = localStorage.getItem('businessMode');

    const privateClientModeToggle = $('.js-private-client');
    const businessModeToggle = $(".js-business");

    const firstType = location.search;


    const paramsString = new URL(location.href);
    const searchParams = new URLSearchParams(paramsString.search);

    const enableBusinessMode = () => {
        $('body').addClass('businessmode');

        businessModeToggle.addClass('active');
        privateClientModeToggle.removeClass('active');

        localStorage.setItem('businessMode', 'enabled');

        const updateURL = () => {
            searchParams.delete('type');

            window.history.replaceState({}, '', `${paramsString}`);
            searchParams.set('type', 'business');
            window.history.replaceState({}, '', `${location.pathname}?${searchParams}`);
        }
        (searchParams.get('type') === 'business') ? '' : updateURL();
    }

    const disableBusinessMode = () => {
        $('body').removeClass('businessmode');
        privateClientModeToggle.addClass('active');
        businessModeToggle.removeClass('active');

        localStorage.setItem('businessMode', null);

        const updateURL = () => {
            searchParams.delete('type');
            window.history.replaceState({}, '', `${paramsString}`);
            searchParams.set('type', 'client');
            window.history.replaceState({}, '', `${location.pathname}?${searchParams}`);
        }
        (searchParams.get('type') === 'client') ? '' : updateURL();
    }

    const handleHashChange = () => {
        document.dispatchEvent(queryParamEvent);

        disableBusinessMode();

        if (searchParams.get('type') === 'business'
            || firstType === '?type=business'
            || businessMode === 'enabled') {
            // enableBusinessMode();
             disableBusinessMode();
        } else if (searchParams.get('type') === 'client'
            || firstType === '?type=client'
            || businessMode === "null") {
            disableBusinessMode();
        }
    }

    // businessModeToggle.click(function () {
    //     (searchParams.get('type') === 'business')
    //         ? ''
    //         : enableBusinessMode();
    // });


    privateClientModeToggle.click(() => {
        (searchParams.get('type') === 'client')
            ? ''
            : disableBusinessMode();
    });

    handleHashChange();
});

