$.removeSticky = function () {
    setTimeout(function () {
        $("#layout-navigation").removeClass("sticky");
    }, 100);
};

$(document).ready(function () {

    /* jQuery scroll to top plugin start */

    $.topbutton({
        htlm: "↑",//String
        css: "", //String
        scrollAndShow: false, //Boolean
        scrollSpeed: 150, //Number
        scrollAndShow: true,
    });

    /* qWuery scrollToTop plugin end */

    /* Contact US Submitting form ( Do you have questions about Kaymaks? )*/
    function toggleCheck() {
        $("#QuestionFormSubmit").prop('disabled', function (i, v) {
            return !v;
        });
    };
    $('#QuestionFormSubmitCheckbox').change(function () {
        toggleCheck();
    });

    /* Used on "How it works" page with pill tab */
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        $(e.target.parentNode.parentNode).find("li").removeClass("active");
        $(e.target.parentNode).addClass("active");
    })

    $('a[data-toggle="tab"]').on('hide.bs.tab', function (e) {
        $(e.target.parentNode).removeClass("active");
    })

    if ($(window).scrollTop() >= 1)  // Проверка состояния прокрутки
    {
        $("html, body").animate({scrollTop: 0}, "slow");
    }

    $('.js-mobile-menu').on('click', function (event) {
        event.preventDefault();
        $('body').toggleClass('overflow-hidden');
    });

});
