
document.addEventListener('DOMContentLoaded', function () {

        $(":input").inputmask();
        $("#inputFeedbackTelephon").inputmask({ "mask": "+9 (999) 999-9999" });
     
        var buttonSubmit = document.querySelectorAll("#QuestionFormFeedbackSubmit"); 
  
    (buttonSubmit.length === 1) ? ( buttonSubmit[0].addEventListener("click", function saveData() {
        var inputFeedbackName = document.getElementById("inputFeedbackName");
        var inputFeedbackEmail = document.getElementById("inputFeedbackEmail");
        var inputFeedbackTelephon = document.getElementById("inputFeedbackTelephon");
        var inputFeedbackMessage = document.getElementById("inputFeedbackMessage"); 

 

        var captcha = grecaptcha.getResponse();

       
        if (captcha.length === 0) {


            sessionStorage.setItem("inputFeedbackName", inputFeedbackName.value);
            sessionStorage.setItem("inputFeedbackEmail", inputFeedbackEmail.value);
            sessionStorage.setItem("inputFeedbackTelephon", inputFeedbackTelephon.value);
            sessionStorage.setItem("inputFeedbackMessage", inputFeedbackMessage.value);

            
        }
        else { 
            sessionStorage.clear();
             

        } 

    })) : "";
    
    const email = sessionStorage.getItem('inputFeedbackEmail');
    const name = sessionStorage.getItem('inputFeedbackName');
    const telephon = sessionStorage.getItem('inputFeedbackTelephon');
    const message = sessionStorage.getItem('inputFeedbackMessage');

    (buttonSubmit.length === 1) ? (
    inputFeedbackEmail.value = email,
    inputFeedbackName.value = name,
    inputFeedbackTelephon.value = telephon,
    inputFeedbackMessage.value = message
    ) : '';
    



});

