{
    const store = {
        localizations: {

        }
    };

    const settings = {
        cookieIcon: '🍪', // Иконка для отображения в заголовке
        cookieName: { // Be careful to change this settings, it's displayed in CookiePolicy law document
            orchardLanguage: 'Public-OrchardCurrentCulture-FrontEnd', // название куксиса, что отвечает за хранение языка
            cookieConsent: 'ff_cookie' // название кукиса в который будет записано значение
        },
        publicEnvironmentLocalizationModule: 'FF.OrchardTheme.CookieConsent', // так называется модуль в локализационном модуле
        cookiePolicyLinkId: 'cookiesPolicyLink', // id, по которому добавляется ссылка на cookiePolicyLink
        baseURL: ``, //window.ff.baseURL, инициализируем только когда будет событие;
        localization: ``, //window.ff.localization, инициализируем только когда будет событие
        cookiePolicyPageLink: `cookies-policy`, // название страницы где хранится текст соглашения об использовании cookies
        localCode: ``, // en-US, en-GB. В зависимости от результата GeoIp
        events: { // названия событий, которые будут всплывать по результату решений
            cookieInit: 'ffCookieConsentInit', // всплывает когда плагин загружен
            cookieAccepted: 'ffCookieConsentAccept', // всплывает когда cookies приняты
            cookieChange: 'ffCookieConsentChange', // всплывает когда решение изменено
            cookieFirstAction: 'ffCookieConsentFirstAction' // всплывает когда решение принято в первый раз
        },
        cookieNames: { // названия кукисов, которые мы обрабатываем и ожидаем увидеть на сайте
            essential: ['RequestVerificationToken', 'ff_cookie', '_GRECAPTCHA'],
            performance: [
                '_fw_crm_v',
                '_ga',
                '_ga_',
                '_ga_C',
                '_gid',
                '_dc_gtm_UA-',
                '_gcl_au',
                '_fbp',
            ],
            functionality: ['Public-OrchardCurrentCulture-FrontEnd']
        },
        isUseAutoStart: location.href.toString().indexOf('cookie') === -1 // автостарт модуля работает на всех страницах, за исключением cookie-policy
    };

    // obtain cookieconsent plugin
    window.cc = initCookieConsent();

    // obtain cookieconsent events
    const cookieInitEvent = new Event(settings.events.cookieInit);
    const cookieAcceptedEvent = new Event(settings.events.cookieAccepted);
    const cookieChangeEvent = new Event(settings.events.cookieChange);
    const cookieFirstActionEvent = new Event(settings.events.cookieFirstAction);


    const checkCookieInClientBrowser = (cookieName) => {
        // return document.cookie.indexOf(cookieName) != -1;
        return window.cc.validCookie(cookieName);
    }

    const deleteCookieInClientBrowser = (cookieName) => {
        return window.cc.eraseCookies(cookieName);
    }

    const cleanUpCookies = (cookiesArray) => {
        if (cookiesArray == null && cookiesArray.length != null) {
            return;
        }

        cookiesArray.forEach(
            cookie => {
                const cookieNameStartPosition = document.cookie.indexOf(cookie);
                if (cookieNameStartPosition > -1) {
                    const cookieNameLength = document.cookie.substr(cookieNameStartPosition).indexOf('=');
                    const fullCookieName = document.cookie.substr(cookieNameStartPosition, cookieNameLength);
                    deleteCookieInClientBrowser(fullCookieName);
                }
            }
        );
    }

    const getServerLocalizationObject = async function (baseUrl, languageCode) {
        const url = `${baseUrl}api/FF.localization/localization/get?module=FF.OrchardTheme.CookieConsent&language=${languageCode}`;
        return await fetch(url).then(response => response.json());
    }

    const getDefaultLocalization = () => {
        return {
            model: [{
                k: "consent.modal.title",
                v: "We use cookies!"
            },
                {
                    k: "consent.modal.description",
                    v: "We use cookies to enhance and simplify the user experience on our website, to offer users a better browsing experience and to remember their settings. According to cookies, we will recognize you on our website and display it to you so that everything works correctly and according to your preferences. Find out more on how we use cookies and how you can"
                },
                {
                    k: "consent.modal.change-your-settings",
                    v: "change your settings"
                },
                {
                    k: "consent.modal.learn-more",
                    v: "Learn more about our"
                },
                {
                    k: "consent.modal.cookies-policy",
                    v: "cookies policy"
                },
                {
                    k: "consent.modal.primary-button",
                    v: "Accept all"
                },
                {
                    k: "consent.modal.secondary-button",
                    v: "Only necessary cookies"
                },
                {
                    k: "consent.settings-modal.save-button",
                    v: "Save settings"
                },
                {
                    k: "consent.settings-modal.accept-button",
                    v: "Accept all"
                },
                {
                    k: "consent.settings-modal.reject-button",
                    v: "Only necessary cookies"
                },
                {
                    k: "consent.settings-modal.close-button",
                    v: "Close"
                },
                {
                    k: "consent.settings-modal.table-headers-name",
                    v: "Name"
                },
                {
                    k: "consent.settings-modal.table-headers-domain",
                    v: "Domain"
                },
                {
                    k: "consent.settings-modal.table-headers-expiration",
                    v: "Expiration"
                },
                {
                    k: "consent.settings-modal.table-headers-description",
                    v: "Description"
                },
                {
                    k: "consent.settings-modal.blocks-one-title",
                    v: "BESTPAY Cookie usage 📢"
                },
                {
                    k: "consent.settings-modal.blocks-one-description",
                    v: "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want."
                },
                {
                    k: "consent.settings-modal.blocks-two-title",
                    v: "Essential cookies"
                },
                {
                    k: "consent.settings-modal.blocks-two-description",
                    v: "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly"
                },
                {
                    k: "consent.settings-modal.blocks-three-title",
                    v: "Performance cookies"
                },
                {
                    k: "consent.settings-modal.blocks-three-description",
                    v: "We rely on performance (analytics) cookies to help us understand how You arrived at our website, how and how long You use it and how we can improve Your user experience. These cookies are anonymous and do not process Your personal data. Some of the performance cookies we use are issued as part of services provided by selected third parties."
                },
                {
                    k: "consent.settings-modal.blocks-four-title",
                    v: "Functionality cookies"
                },
                {
                    k: "consent.settings-modal.blocks-four-description",
                    v: "These cookies allow us to remember choices You make on our website and provide enhanced, more personal features for You."
                },
                {
                    k: "consent.settings-modal.blocks-five-title",
                    v: "Analytics cookies"
                },
                {
                    k: "consent.settings-modal.blocks-five-description",
                    v: "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you."
                },
            ],
            error: null,
            isSuccess: true
        }
    }

    const disablePerformanceCookies = () => {

        const disableGoogleTagManager = () => {
            typeof gtag === 'function' && gtag('consent', 'update', {
                'analytics_storage': 'denied'
            });
        }

        const disableFacebookPixel = () => {
            // disable facebook pixel
            if (window.fbq != null) {
                fbq.disablePushState = true;
                deleteCookieInClientBrowser('_fbp');
            }
        }

        const disableFreshChat = () => {
            // disable freshchat
            if (window.fcWidget != null) {
                window.fcWidget.destroy();
                deleteCookieInClientBrowser('_fw_crm_v');
            }
        }

        disableGoogleTagManager();
        disableFacebookPixel();
        disableFreshChat();
        cleanUpCookies(settings.cookieNames.performance);
    }

    const disableFunctionalityCookies = () => {
        cleanUpCookies(settings.cookieNames.functionality);
    }
    
    const startCookieConsentModule = (autorun = true) => {
        const cleanDefaultCookiesAfterInit = () => {
            const userPreferences = window.cc.getUserPreferences();
            const rejectedCategories = userPreferences.rejected_categories;

            if (!checkCookieInClientBrowser(settings.cookieName.cookieConsent) || rejectedCategories.length > 0) {

                if (rejectedCategories.indexOf('performance') > -1) {
                    if (!window.cc.allowedCategory('performance')) {
                        disablePerformanceCookies();                        
                    } else {
                        
                        window.location.reload();  
                    }
                }

                if (rejectedCategories.indexOf('functionality') > -1) {
                    if (!window.cc.allowedCategory('functionality')) {
                        disableFunctionalityCookies();                    
                    } else {
                        
                        window.location.reload();  
                    }
                }
                
            }
        }
       
        // run plugin with config object
        window.cc.run({
            autorun: true, // default: true
            current_lang: 'default',
            mode: 'opt-in',
            autoclear_cookies: true, // default: false
            cookie_name: settings.cookieName.cookieConsent, // default: 'cc_cookie'
            cookie_expiration: 365, // default: 182
            page_scripts: true, // default: false
            force_consent: false, // Enable if you want to block page navigation until user action
            hide_from_bots: true, // default: false
            remove_cookie_tables: true, // default: false
            //theme_css: '../src/cookieconsent.css',
            // auto_language: null,                     // default: null; could also be 'browser' or 'document'
            // delay: 1000,                                // default: 0
            // cookie_domain: location.hostname,        // default: current domain
            // cookie_path: "/",                        // default: root
            // cookie_same_site: "Lax",
            // use_rfc_cookie: false,                   // default: false
            // revision: 0,                             // default: 0

            gui_options: {
                consent_modal: {
                    layout: 'box', // box,cloud,bar
                    position: 'bottom right', // bottom,middle,top + left,right,center
                    transition: 'zoom' // zoom,slide
                },
                settings_modal: {
                    layout: 'box', // box,bar
                    transition: 'zoom' // zoom,slide
                }
            },

            onFirstAction:(cookieName) => {
                document.dispatchEvent(cookieFirstActionEvent);
                cleanDefaultCookiesAfterInit();

                return;
            },

            onAccept: (cookie) => {
                document.dispatchEvent(cookieAcceptedEvent);
            },

            onChange: (cookie, changed_preferences) => {
                document.dispatchEvent(cookieChangeEvent);

                if (changed_preferences.indexOf('performance') > -1) {
                    if (!window.cc.allowedCategory('performance')) {
                        disablePerformanceCookies();
                    }
                }

                // If functionality category's status was changed ...
                if (changed_preferences.indexOf('functionality') > -1) {
                    if (!window.cc.allowedCategory('functionality')) {
                        disableFunctionalityCookies();
                    }
                }

                return;
            },

            languages: {
                default: {
                    consent_modal: {
                        title: `${settings.cookieIcon} ${store.localizations['consent.modal.title']}`,
                        description: `${store.localizations['consent.modal.description']}
                                  <button type="button" data-cc="c-settings" class="cc-link"> ${store.localizations['consent.modal.change-your-settings']}</button>
                                  <p class="mt-2">${store.localizations['consent.modal.learn-more']}
                                      <a id="${settings.cookiePolicyLinkId}" class="cc-link" href="#">
                                          ${store.localizations['consent.modal.cookies-policy']}
                                      </a>
                                  </p>`,
                        primary_btn: {
                            text: `${store.localizations['consent.modal.primary-button']}`,
                            role: 'accept_all' // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: `${store.localizations['consent.modal.secondary-button']}`,
                            role: 'accept_necessary' // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: '', //logo,
                        save_settings_btn: `${store.localizations['consent.settings-modal.save-button']}`,
                        accept_all_btn: `${store.localizations['consent.settings-modal.accept-button']}`,
                        reject_all_btn: `${store.localizations['consent.settings-modal.reject-button']}`,
                        close_btn_label: `${store.localizations['consent.settings-modal.close-button']}`,
                        cookie_table_headers: [{
                            col1: `${store.localizations['consent.settings-modal.table-headers-name']}`
                        },
                            {
                                col2: `${store.localizations['consent.settings-modal.table-headers-domain']}`
                            },
                            {
                                col3: `${store.localizations['consent.settings-modal.table-headers-expiration']}`
                            },
                            {
                                col4: `${store.localizations['consent.settings-modal.table-headers-description']}`
                            }
                        ],
                        blocks: [{
                            title: `${store.localizations['consent.settings-modal.blocks-one-title']}`,
                            description: `${store.localizations['consent.settings-modal.blocks-one-description']}`
                        }, {
                            title: `${store.localizations['consent.settings-modal.blocks-two-title']}`,
                            description: `${store.localizations['consent.settings-modal.blocks-two-description']}`,
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: `${store.localizations['consent.settings-modal.blocks-three-title']}`,
                            description: `${store.localizations['consent.settings-modal.blocks-three-description']}`,
                            toggle: {
                                value: 'performance', // there are no default categories => you specify them
                                enabled: false,
                                readonly: false
                            }
                        }, {
                            title: `${store.localizations['consent.settings-modal.blocks-four-title']}`,
                            description: `${store.localizations['consent.settings-modal.blocks-four-description']}`,
                            toggle: {
                                value: 'functionality', // there are no default categories => you specify them
                                enabled: false,
                                readonly: false
                            },
                        }]
                    }
                }
            }
        });

        cleanDefaultCookiesAfterInit();
        document.dispatchEvent(cookieInitEvent);
    }

    const updateLocalizations = (serverResponse) => {
        let remappedServerResponse = { ...serverResponse };

        if (remappedServerResponse.model == null || serverResponse.model.length === 0) {
            // Mock
            remappedServerResponse = getDefaultLocalization();
        }

        remappedServerResponse.model.forEach(r => {
            store.localizations[r.k] = r.v
        });
    }

    const setLinkToCookiesPolicy = () => {
        const node = document.getElementById(`${settings.cookiePolicyLinkId}`);
        if (node != null) {
            node.setAttribute('href', `${settings.baseURL}/${settings.localCode}/${settings.cookiePolicyPageLink}`);
        }
    }

    const addCloseButton = () => {
        // By default cookie-consent module not support close button. This is our realization of it.
        const buttonsContainer = document.getElementById('c-bns');
        const button = document.createElement('button');
        button.id = 'c-c-bn';
        button.class = 'c-bn c_link';
        button.type = 'button';
        button.textContent = '✕'

        buttonsContainer.append(button);

        button.addEventListener('click', () => {
            const cmElement = document.getElementById('cm');
            if (cmElement) {
                cmElement.remove();
            }
        });
    }

    const IsCloseButtonExist = () => {
        if (document.getElementById('cm') !== null) {
            addCloseButton();
        }
    }

    const startCookie = () => {
        const handleLocalizationError = () => {
            updateLocalizations(); 
            startCookieConsentModule(settings.isUseAutoStart);
    
            if (settings.isUseAutoStart) {
                setLinkToCookiesPolicy();
            }
    
            IsCloseButtonExist();
    
            console.error('Cannot initialize cookie bar localization');
        };
        getServerLocalizationObject(settings.baseURL, settings.localization)
            .then(serverResponse => {    
                updateLocalizations(serverResponse);
            })
            .catch(handleLocalizationError) 
            .finally(() => {
                startCookieConsentModule(settings.isUseAutoStart);  
                if (settings.isUseAutoStart) {
                    setLinkToCookiesPolicy();
                }
    
                IsCloseButtonExist();
            });
    };

    // Куки-бар инициализируется только после инициализации параметров с baseURL
    document.addEventListener('ffParamsInit', function (e) {
        if (window.ff.baseURL != null) {
            settings.baseURL = window.ff.baseURL;
        }

        if (window.ff.localization != null) {
            settings.localization = window.ff.localization;
        }

        if (window.ff.localCode != null) {
            settings.localCode = window.ff.localCode;
        }

        startCookie();

    }, false);

    // window.settings = settings

}