$(function () {

    var owl = $(".js-advantages-owlcarousel");

    owl.owlCarousel({
        items: 2,
        loop: true,
        nav: false,
        autoplay: true,
        autoHeight: true,
        autoplayTimeout: 5000,
        smartSpeed: 3000,
        stagePadding: 215,
        margin: 30,
        responsive: {
            0: {
                items: 1.1,
                margin: 16,
                stagePadding: 0,
            },
            768: {
                loop: true,
                items: 1,
                stagePadding: 70,
            },
            1200: {
                loop: true,
                items: 1,
                stagePadding: 215,
            },
            1900: {
                items: 2,
                stagePadding: 215,
                autoHeight: false,
            },
        }
    });
 
    var owl = $('.owl-carousel');
    owl.owlCarousel({
      loop: false, 
      dots: false,
      margin: 30,
      autoWidth: true,
      center: true,
      items: 2,
      responsive: {
        0: {
            loop: true, 
            items: 1,
            center: false,
            autoWidth: false,
            autoHeight: true,
        },
        650: {
            loop: true, 
            items: 1,
            autoWidth: false,
            center: false,
            autoHeight: true,
        },
        768: {
            items: 2,
            autoHeight: false, 
        }, 
        2540: {
            autoWidth: false,
            items: 2,
            autoHeight: false,
            margin: 30,
        }, 
    }
    });
    
    // Custom Button
    $('.btn-next').click(function() {
      owl.trigger('prev.owl.carousel');
    });
    $('.btn-prev').click(function() {
      owl.trigger('next.owl.carousel');
    });



    var infoCaruselBox = $('.js-carousel-facts');
    infoCaruselBox.addClass('owl-carousel').owlCarousel({
        margin: 30,
        // autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        autoHeight: false,
        loop: true,
        nav: true,
        dots: false,
        items: 1,
        responsive: {
            0: {
                items: 1,
                autoHeight: true,
            },
            768: {
                items: 1,
                autoHeight: false,
            },
        }
    });
});

 